import { useState } from "react";
import styled, { css } from "styled-components";
import { Icon } from "./Icon";
import WerkInfo from "./WerkInfo";

const StyledModalWerk = styled.div`
  max-height: calc(100% - 100px);
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: calc(100vh - 100px);
  height: 100%;
  max-height: calc(100vh - 100px);
  object-fit: contain;
`;

const StyledInfo = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 5px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px 40px 40px;
  border-radius: 6px;
  background: #000;
  color: #fff;

  @media screen and (min-width: 768px) {
    position: absolute;
    right: 40px;
    bottom: 45px;
    left: 40px;
    padding: 15px;
    background: rgba(0, 0, 0, 0.8);
  }
`;

const StyledInfoTitle = styled.h2`
  margin: 15px 0;
  font-size: 20px;
  font-weight: normal;
`;

const StyledIconWrapper = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  padding: 5px;
  background-color: #fff;
  color: #000;
  cursor: pointer;

  ${({ showType }) =>
    showType === "hideInfo" &&
    css`
      top: 30px;
      right: 45px;

      @media screen and (min-width: 768px) {
        top: 10px;
        right: 10px;
      }
    `}

  ${({ showType }) =>
    showType === "showInfo" &&
    css`
      top: 30px;
      right: 70px;
    `}

  ${({ showType }) =>
    showType === "closeModal" &&
    css`
      top: 30px;
      right: 30px;
    `}
`;

const ModalWerk = ({ werk, className }) => {
  const [info, setInfo] = useState(true);

  const handleInfo = (state) => {
    setInfo(!state);
  };

  return (
    <StyledModalWerk className={className}>
      {!info && (
        <StyledIconWrapper onClick={() => handleInfo(info)} showType="showInfo">
          <Icon name="info" />
        </StyledIconWrapper>
      )}

      <StyledImage src={werk.image.src} alt={werk.image.alt} />
      {info && (
        <StyledInfo>
          <StyledIconWrapper
            onClick={() => handleInfo(info)}
            showType="hideInfo"
          >
            <Icon name="close" />
          </StyledIconWrapper>
          <StyledInfoTitle>{werk.title}</StyledInfoTitle>
          <WerkInfo groessen={werk.info.groessen} />
        </StyledInfo>
      )}
    </StyledModalWerk>
  );
};

export default ModalWerk;
