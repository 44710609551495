import styled, { css } from "styled-components";

const StyledGridItem = styled.article`
  position: relative;
  overflow: hidden;

  ${({ featured }) =>
    featured &&
    css`
      padding: 5px;
      background-color: #fff;
    `}

  &:hover {
    cursor: pointer;

    header {
      transform: translateY(0);
    }

    img {
      scale: 120%;
    }
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  transition: scale 0.2s ease-in;

  ${({ featured }) =>
    featured
      ? css`
          object-fit: contain;
        `
      : css`
          object-fit: cover;
        `}
`;

const StyledTitle = styled.header`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  transform: translateY(100%);
  transition: transform 0.2s ease-in;
`;

const GridItem = ({ werk, className, id, handleClick, handleClose }) => {
  const featured = werk.isFeatured ? "ff" : "";
  const classNameResolved = className + " " + id + " " + featured;
  const image = werk.image;

  return (
    <>
      <StyledGridItem
        className={classNameResolved}
        featured={werk.isFeatured}
        onClick={handleClick}
      >
        <StyledImage
          onClick={handleClose}
          src={image.thumb}
          alt={image.alt}
          loading="lazy"
          featured={werk.isFeatured}
        />
        <StyledTitle>{werk.title}</StyledTitle>
      </StyledGridItem>
    </>
  );
};

export default GridItem;
