import React from "react";
import Grid from "./Grid";

const Layout = ({ data, className }) => {
  return (
    <Grid
      className={className}
      werke={data.werke}
      nextEvents={data.nextEvents}
      nextEventTeaser={data.nextEventTeaser}
      events={data.events}
      featured={data.featured}
    />
  );
};

export default Layout;
