import { useEffect } from "react";
import GlobalStyle from "./components/GlobalStyles";
import Layout from "./components/Layout";
import data from "./lib/i18n.json";

function App() {
  useEffect(() => {
    document.title = data.general.projectTitle;
  });

  // Redirect to root
  const currentPath = window.location.pathname;
  if (currentPath !== "/") {
    window.location.pathname = "/";
  }

  return (
    <>
      <GlobalStyle />
      <div className="App">
        <Layout data={data} />
      </div>
    </>
  );
}

export default App;
