import styled from "styled-components";
import { Icon } from "./Icon";

const StyledModalAusstellungen = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  hyphens: none;
`;

const StyledNextEvent = styled.div``;

const StyledHeadline = styled.h2`
  margin-top: 10px;
`;

const StyledCopy = styled.div`
  p {
    line-height: 1.5;
  }
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 6px;
  padding: 20px;
  background-color: #f5f5f5;

  p {
    margin: 0;
  }
`;

const StyledInfoRow = styled.div`
  display: flex;
  gap: 20px;
`;

const StyledPastEvents = styled.div``;

const StyledEvents = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0;
  border-left: 2px solid #000;
  padding: 0 0 0 20px;
  list-style: none;
`;

const StyledEvent = styled.li`
  position: relative;
  display: flex;
  gap: 15px;

  &::before {
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    content: "";
    border: 2px solid #000;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border-radius: 10px;
  }
`;

const StyledEventYear = styled.span`
  font-weight: bold;
`;

const StyledEventLocation = styled.span``;

const ModalAusstellungen = ({
  events,
  nextEvents,
  nextEventTeaser,
  className,
}) => (
  <StyledModalAusstellungen className={className}>
    <img src={nextEventTeaser.src} alt={nextEventTeaser.alt} width="100%" />
    {nextEvents.map((event, index) => (
      <StyledNextEvent key={index}>
        <StyledHeadline>{event.headline}</StyledHeadline>
        <StyledCopy dangerouslySetInnerHTML={{ __html: event.copy }} />
        <StyledInfo>
          <StyledInfoRow>
            <Icon name="location" />
            <StyledCopy dangerouslySetInnerHTML={{ __html: event.location }} />
          </StyledInfoRow>
          <StyledInfoRow>
            <Icon name="calendar" />
            <StyledCopy dangerouslySetInnerHTML={{ __html: event.date }} />
          </StyledInfoRow>
        </StyledInfo>
      </StyledNextEvent>
    ))}
    <StyledPastEvents>
      <StyledHeadline>Unsere größeren Ausstellungen</StyledHeadline>
      <StyledEvents>
        {events.map((event, index) => (
          <StyledEvent key={index}>
            <StyledEventYear>{event.year}</StyledEventYear>
            <StyledEventLocation>{event.location}</StyledEventLocation>
          </StyledEvent>
        ))}
      </StyledEvents>
    </StyledPastEvents>
  </StyledModalAusstellungen>
);

export default ModalAusstellungen;
