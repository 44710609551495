import styled, { css } from "styled-components";
import { Icon } from "./Icon";

const StyledModalContent = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
`;

const StyledContent = styled.div`
  box-sizing: border-box;
  position: relative;
  background: #000;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 30px);
  border-radius: 20px;
  padding: 20px;
  color: #fff;
  overflow-y: scroll;

  ${({ isWerk }) =>
    !isWerk &&
    css`
      width: 600px;
    `};

  a {
    color: white;
  }
`;

const StyledIconWrapper = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  padding: 5px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
`;

const StyledButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
`;

const ModalContent = ({ children, handleModalClose, isWerk }) => (
  <StyledModalContent>
    <StyledButton onClick={handleModalClose} />
    <StyledContent isWerk={isWerk}>
      <StyledIconWrapper onClick={handleModalClose}>
        <Icon name="close" />
      </StyledIconWrapper>
      {children}
    </StyledContent>
  </StyledModalContent>
);

export default ModalContent;
