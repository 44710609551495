import React, { useState } from "react";
import styled from "styled-components";

import Ausstellungen from "./Ausstellungen";
import Featured from "./Featured";
import GridItem from "./GridItem";
import ModalAusstellungen from "./ModalAusstellungen";
import ModalContent from "./ModalContent";
import ModalImprint from "./ModalImprint";
import ModalFeatured from "./ModalFeatured";
import ModalWerk from "./ModalWerk";
import Impressum from "./Impressum";

const StyledGrid = styled.div`
  .ff {
    grid-area: ff;
    padding: 16px;
    background-color: #fff;
  }

  .img12 {
    grid-area: i12;
  }

  .img23 {
    grid-area: i23;
  }

  .img27 {
    grid-area: i27;
  }

  display: grid;
  grid-auto-rows: 1fr;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-template:
    "ff  ff "
    ".   .  " 210px
    "au  au " 210px
    ".   .  " 210px
    ".   .  " 210px
    "i23 i23" 210px
    "i23 i23" 210px
    ".   .  " 210px
    ".   i12" 210px
    ".   i12" 210px
    ".   .  " 210px
    ".   .  " 210px
    ".   .  " 210px
    "i27 .  " 210px
    "i27 .  " 210px
    "i27 .  " 210px
    ".   .  " 210px
    ".   .  " 210px
    ".   .  " 210px
    ".   .  " 210px
    ".   .  " 210px
    "im  im ";

  @media screen and (min-width: 768px) {
    grid-template:
      "ff  ff  ff "
      ".   .   .  " 300px
      "au  au  au " 300px
      ".   .   .  " 300px
      ".   .   .  " 300px
      "i23 i23 .  " 300px
      "i23 i23 .  " 300px
      ".   .   .  " 300px
      ".   i12 .  " 300px
      ".   i12 .  " 300px
      ".   .   .  " 300px
      ".   i26 i26" 300px
      ".   i26 i26" 300px
      "i27 .   .  " 300px
      "i27 .   .  " 300px
      "i27 i24 i24" 300px
      "im  im  im ";

    .ff {
      padding: 32px;
    }

    .img24 {
      grid-area: i24;
    }

    .img26 {
      grid-area: i26;
    }
  }

  @media screen and (min-width: 992px) {
    grid-template:
      "ff  ff  .  " 340px
      ".   .   .  " 340px
      "au  au  au " 170px
      ".   .   .  " 340px
      ".   .   .  " 340px
      "i12 i12 .  " 340px
      "i12 i12 .  " 340px
      ".   .   .  " 340px
      ".   .   .  " 340px
      ".   i27 .  " 340px
      ".   i27 .  " 340px
      ".   i27 .  " 340px
      ".   .   .  " 340px
      ".   .   .  " 340px
      "im  im  im " 170px;

    .img23 {
      grid-area: unset;
    }

    .img24 {
      grid-area: unset;
    }

    .img26 {
      grid-area: unset;
    }
  }

  @media screen and (min-width: 1440px) {
    grid-template:
      ".   .   .   .   .   au  au  .  " 230px
      ".   ff  ff  ff  .   i12 .   .  " 230px
      ".   ff  ff  ff  .   i12 i27 .  " 230px
      "i23 i23 .   .   .   .   i27 .  " 230px
      "i23 i23 .   .   .   .   i27 .  " 230px
      ".   .   .   .   .   im  im  .  " 230px;

    .img23 {
      grid-area: i23;
    }
  }
`;

const Grid = ({
  werke,
  events,
  nextEvents,
  nextEventTeaser,
  featured,
  className,
}) => {
  const [modalType, setModalType] = useState();
  const [modalData, setModalData] = useState();

  const handleModal = (type, data) => {
    setModalType(type);
    if (data) setModalData(data);
  };

  const handleModalClose = () => {
    setModalType(null);
  };

  return (
    <StyledGrid className={className}>
      {werke &&
        werke.map((werk, index) => (
          <GridItem
            key={werk.title}
            werk={werk}
            id={"img" + index}
            handleClick={() => handleModal("werk", werk)}
            handleClose={() => handleModalClose()}
          />
        ))}
      <Featured
        featured={featured}
        className="ff"
        onClick={() => handleModal("featured")}
      />
      <Ausstellungen
        className="au"
        onClick={() => handleModal("ausstellungen")}
      />
      <Impressum className="im" onClick={() => handleModal("imprint")} />
      {modalType && (
        <ModalContent
          handleModalClose={() => handleModalClose()}
          isWerk={modalType === "werk"}
        >
          {modalType === "featured" && <ModalFeatured featured={featured} />}
          {modalType === "imprint" && <ModalImprint />}
          {modalType === "werk" && modalData && <ModalWerk werk={modalData} />}
          {modalType === "ausstellungen" && (
            <ModalAusstellungen
              events={events}
              nextEvents={nextEvents}
              nextEventTeaser={nextEventTeaser}
              className="au"
            />
          )}
        </ModalContent>
      )}
    </StyledGrid>
  );
};

export default Grid;
