import styled from "styled-components";

const StyledImpressum = styled.div`
  grid-area: im;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  color: #000;
  background-color: #fff;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.3s ease-in, background-color 0.3s ease-in;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #000;
  }
`;

const Impressum = ({ onClick, className }) => (
  <StyledImpressum className={className} onClick={onClick}>
    Impressum
  </StyledImpressum>
);

export default Impressum;
