import styled from "styled-components";

const StyledModalFeatured = styled.div`
  padding: 32px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
`;

const StyledImageWrapper = styled.div`
  margin-bottom: 32px;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const StyledHeadline = styled.h1`
  line-height: 1.2;
`;

const StyledCopy = styled.p`
  font-size: 18px;
  line-height: 1.5;
`;

const FeaturedModal = ({ featured, className }) => {
  console.log(featured.image.thumb);
  return (
    <StyledModalFeatured className={className}>
      <StyledImageWrapper>
        <StyledImage src={featured.image.src} loading="lazy" />
      </StyledImageWrapper>
      <StyledHeadline>
        Rüdiger Philipps &amp;
        <br />
        Angelika Darius
      </StyledHeadline>
      <StyledCopy>
        Angelika Darius und Rüdiger Philipps lernten sich über die Musik kennen:
        Angelika Darius war Sängerin in der Blues und Rock Band „Bad Cat“,
        Rüdiger Philipps stieß später als Blues Harper dazu. Man stellte fest,
        dass es außer der Musik eine weitere Gemeinsamkeit gab: das Malen.
        Angelika Darius malte abstrakte, großformatige Bilder in Acryl und Öl,
        Rüdiger Philipps realistische, surrealistische, kleinformatige Werke in
        Aquarell. Diese unterschiedlichen Herangehensweisen eröffnen neue
        kreative Spielräume. Entsprechend groß ist die Bandbreite der
        Bildgestaltung. Ruhige, abstrakte Werke sind ebenso Bestandteil der
        Ausstellung wie kraftvolle, realistische und auch surrealistische
        Darstellungen. Angelika Darius und Rüdiger Philipps legen Wert darauf,
        dass ihre Bilder Spielraum für eigene Interpretationen bieten. Alle
        aufgeführten Bilder sind auch als Drucke in den von Ihnen gewünschten
        Formaten für den Außenbereich erhältlich.Das Künstlerduo stellt seine
        Werke seit mehreren Jahren u.a. im Sandbauernhof in Korschenbroich /
        Liedberg aus.
      </StyledCopy>
    </StyledModalFeatured>
  );
};

export default FeaturedModal;
