import React from "react";
import styled, { css } from "styled-components";

const sizes = {
  small: css`
    width: var(--icon-size-s);
    height: var(--icon-size-s);
  `,
  medium: css`
    width: var(--icon-size-m);
    height: var(--icon-size-m);
  `,
  large: css`
    width: var(--icon-size-l);
    height: var(--icon-size-l);
  `,
};

const StyledSVG = styled.svg`
  fill: currentColor;
  pointer-events: none;

  ${({ size }) => sizes[size]}
`;

export const Icons = {
  info: ({ size, className }) => (
    <StyledSVG
      size={size}
      className={className}
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M24 12c0 6.6-5.4 12-12 12S0 18.6 0 12 5.4 0 12 0s12 5.4 12 12zM12 2.2c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8z" />
      <path d="M13.9 15.8h-.4v-4.1c0-.6-.5-1.1-1.1-1.1h-2.2c-.7-.1-1.2.4-1.2 1s.5 1.1 1.1 1.1h1.1v3h-1.1c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h3.8c.6 0 1.1-.5 1.1-1.1 0-.5-.5-1-1.1-1z" />
      <circle cx="12" cy="7.5" r="1.5" />
    </StyledSVG>
  ),
  close: ({ size, className }) => (
    <StyledSVG
      size={size}
      className={className}
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M8.2 8.2c.4-.4 1.2-.4 1.6 0l2.2 2.2 2.2-2.2c.4-.4 1.2-.4 1.6 0 .5.4.5 1.2 0 1.6L13.6 12l2.2 2.2c.5.4.5 1.2 0 1.6-.4.5-1.1.5-1.6 0L12 13.6l-2.2 2.2c-.4.5-1.1.5-1.6 0-.4-.4-.4-1.1 0-1.6l2.2-2.2-2.2-2.2c-.4-.4-.4-1.2 0-1.6zM24 12c0 6.6-5.4 12-12 12S0 18.6 0 12 5.4 0 12 0s12 5.4 12 12zM12 2.2c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8z" />
    </StyledSVG>
  ),
  calendar: ({ size, className }) => (
    <StyledSVG
      size={size}
      className={className}
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <circle cx="12" cy="7.5" r="1.5" />
      <path d="M8.6 3h6.8V1.1c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1V3h1.9c1.7 0 3 1.3 3 3v15c0 1.7-1.3 3-3 3h-15c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3h1.9V1.1C6.4.5 6.9 0 7.5 0s1.1.5 1.1 1.1V3zM3.8 21c0 .4.3.8.8.8h15c.4 0 .8-.3.8-.8V9H3.8v12z" />
    </StyledSVG>
  ),
  location: ({ size, className }) => (
    <StyledSVG
      size={size}
      className={className}
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M12 0C7 0 3 4 3 9c0 4.1 5.5 11.4 7.9 14.4.6.7 1.6.7 2.2 0C15.5 20.4 21 13.1 21 9c0-5-4-9-9-9zm0 13c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z" />
    </StyledSVG>
  ),
};

export const Icon = ({ className, name, size = "medium" }) =>
  name ? Icons[name]({ size, className }) : null;
