import styled from "styled-components";

const StyledFeatured = styled.div`
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Featured = ({ featured, onClick, className }) => (
  <StyledFeatured onClick={onClick} className={className}>
    <StyledImage src={featured.image.src} loading="eager" />
  </StyledFeatured>
);

export default Featured;
