import styled from "styled-components";

const StyledModalImprint = styled.div`
  padding: 32px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  line-height: 1.5;

  a {
    color: #000;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;
  }
`;

const ModalImprint = ({ className }) => (
  <StyledModalImprint className={className}>
    <h2>Angaben gemäß § 5 TMG:</h2>
    <p>
      Galerie Philipps Darius
      <br />
      Rüdiger Philipps
    </p>
    <h3>Postanschrift:</h3>
    <p>
      Pescher Strasse 111a
      <br />
      41352
    </p>
    <h3>Kontakt:</h3>
    <p>
      Telefon: 02161-642177
      <br />
      E-Mail: galerie(at)philipps-darius.de
    </p>
    <h3>Vertreten durch:</h3>
    <p>Rüdiger Philipps</p>
    <h3>Umsetzung:</h3>
    <p>
      <a
        title="HELLWACH Medien GmbH"
        href="http://www.hellwach-medien.de"
        target="_blank"
        rel="noreferrer"
      >
        HELLWACH Medien GmbH
      </a>
    </p>
    <h3>Fotografie:</h3>
    <p>
      <a
        title="Fotostudio Weimbs"
        href="http://www.der-fotografenmeister.de"
        target="_blank"
        rel="noreferrer"
      >
        Stefan Weimbs
      </a>
    </p>
    <h2>Datenschutzerklärung:</h2>
    <p>
      <strong>Datenschutz</strong>
    </p>
    <p>
      Nachfolgend möchten wir Sie über unsere Datenschutzerklärung informieren.
      Sie finden hier Informationen über die Erhebung und Verwendung
      persönlicher Daten bei der Nutzung unserer Webseite. Wir beachten dabei
      das für Deutschland geltende Datenschutzrecht. Sie können diese Erklärung
      jederzeit auf unserer Webseite abrufen.
    </p>
    <p>
      Wir weisen ausdrücklich darauf hin, dass die Datenübertragung im Internet
      (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen und
      nicht lückenlos vor dem Zugriff durch Dritte geschützt werden kann.
    </p>
    <p>
      Die Verwendung der Kontaktdaten unseres Impressums zur gewerblichen
      Werbung ist ausdrücklich nicht erwünscht, es sei denn wir hatten zuvor
      unsere schriftliche Einwilligung erteilt oder es besteht bereits eine
      Geschäftsbeziehung. Der Anbieter und alle auf dieser Website genannten
      Personen widersprechen hiermit jeder kommerziellen Verwendung und
      Weitergabe ihrer Daten.
    </p>
    <p>
      <strong>Personenbezogene Daten</strong>
    </p>
    <p>
      Sie können unsere Webseite ohne Angabe personenbezogener Daten besuchen.
      Soweit auf unseren Seiten personenbezogene Daten (wie Name, Anschrift oder
      E-Mail Adresse) erhoben werden, erfolgt dies, soweit möglich, auf
      freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung
      nicht an Dritte weitergegeben. Sofern zwischen Ihnen und uns ein
      Vertragsverhältnis begründet, inhaltlich ausgestaltet oder geändert werden
      soll oder Sie an uns eine Anfrage stellen, erheben und verwenden wir
      personenbezogene Daten von Ihnen, soweit dies zu diesen Zwecken
      erforderlich ist (Bestandsdaten). Wir erheben, verarbeiten und nutzen
      personenbezogene Daten soweit dies erforderlich ist, um Ihnen die
      Inanspruchnahme des Webangebots zu ermöglichen (Nutzungsdaten). Sämtliche
      personenbezogenen Daten werden nur solange gespeichert wie dies für den
      geannten Zweck (Bearbeitung Ihrer Anfrage oder Abwicklung eines Vertrags)
      erforderlich ist. Hierbei werden steuer- und handelsrechtliche
      Aufbewahrungsfristen berücksichtigt. Auf Anordnung der zuständigen Stellen
      dürfen wir im Einzelfall Auskunft über diese Daten (Bestandsdaten)
      erteilen, soweit dies für Zwecke der Strafverfolgung, zur Gefahrenabwehr,
      zur Erfüllung der gesetzlichen Aufgaben der Verfassungsschutzbehörden oder
      des Militärischen Abschirmdienstes oder zur Durchsetzung der Rechte am
      geistigen Eigentum erforderlich ist.
    </p>
    <p>
      <strong>Kommentarfunktionen</strong>
    </p>
    <p>
      Im Rahmen der Kommentarfunktion erheben wir personenbezogene Daten (z.B.
      Name, E-Mail) im Rahmen Ihrer Kommentierung zu einem Beitrag nur in dem
      Umfang wie Sie ihn uns mitgeteilt haben. Bei der Veröffentlichung eines
      Kommentars wird die von Ihnen angegebene Email-Adresse gespeichert, aber
      nicht veröffentlicht. Ihr Name wird veröffentlich, wenn Sie nicht unter
      Pseudonym geschrieben haben.
    </p>
    <p>
      <strong>Auskunftsrecht</strong>
    </p>
    <p>
      Sie haben das jederzeitige Recht, sich unentgeltlich und unverzüglich über
      die zu Ihrer Person erhobenen Daten zu erkundigen. Sie haben das
      jederzeitige Recht, Ihre Zustimmung zur Verwendung Ihrer angegeben
      persönlichen Daten mit Wirkung für die Zukunft zu widerrufen. Zur
      Auskunftserteilung wenden Sie sich bitte an den Anbieter unter den
      Kontaktdaten im Impressum.
    </p>
  </StyledModalImprint>
);

export default ModalImprint;
