import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --icon-size-m: 20px;
    }

  body {
    margin: 0;
    background: #000;
    font-family: Open-Sans, Helvetica, Sans-Serif;
    hyphens: auto;
  }
`;

export default GlobalStyle;
