import styled from "styled-components";

const StyledAusstellungen = styled.article`
  grid-area: au;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  color: #000;
  background-color: #fff;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.3s ease-in, background-color 0.3s ease-in;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #000;
  }
`;

const Ausstellungen = ({ events, onClick, className }) => (
  <StyledAusstellungen className={className} onClick={onClick}>
    Ausstellungen
  </StyledAusstellungen>
);

export default Ausstellungen;
